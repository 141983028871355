import { Container, Row, Col } from "react-bootstrap";
import './footer.css';
import logo from "../assets/img/logo_red.svg"
import navIcon1 from '../assets/img/nav-icon1.svg';
import facebook from '../assets/img/nav-icon2.svg';
import instagram from '../assets/img/nav-icon3.svg';

export const Footer = () => {
  return (
    <footer className="footer">
      <Container>
        <Row className="align-item-center">
          <Col sm={6}>
            <img src={logo} alt="logo" loading="lazy" />
          </Col>
          <Col sm={6} className="text-center text-sm-end">
            <div className="social-icon">
              <a aria-label="linkedin" href="https://www.linkedin.com/company/99254281/admin/feed/posts/" target="_blank"  rel="noreferrer"><img alt="linkedin logo" src={navIcon1} /></a>
              <a aria-label="facebook" href="https://www.facebook.com/LunarNewYearforAll" target="_blank" rel="noreferrer"><img alt="facebook logo" src={facebook} /></a>
              <a aria-label="instagram" href="https://www.instagram.com/lunarnewyearforall/" target="_blank" rel="noreferrer"><img alt="instagram logo" src={instagram} /></a>
            </div>
            <p>Copyright 2024. All Rights Reserved by Lunar New Year for All.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
