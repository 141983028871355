import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import emailjs from '@emailjs/browser';
import { Container, Row, Col } from "react-bootstrap";
import './contact.css';

export const Contact = () => {
  const form = useRef();
  const refCaptcha = useRef();
  const siteKey = process.env.REACT_APP_SITE_KEY;

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_6ftepua', 'template_pv6tvtb', form.current, 'QFe67nf-atWZ6NqlN')
      .then(() => {
        alert("Message has been sent!");
        const form = document.querySelector('#contact-form');
        form.reset();
      }, (error) => {
        console.log(error.text);
        alert("There was an error when sending your message, please try again later.");
      });
  };

  return (
    <section className="contact" id="contact">
      <Container>
        <div className="contact-border">
          <h1>Contact Us</h1>
          <Row className="align-items-center">
            <Col className='px-3' md={1}></Col>
            <Col className='px-3' md={11}>
              <form id="contact-form" className="text-center" ref={form}>
                <Row>
                  <Col className="d-flex flex-column align-items-center">
                    <label htmlFor="name">
                      Name
                      <input type="text" name="name" required />
                    </label>
                    <label htmlFor="email">
                      Email
                      <input type="email" name="email" required />
                    </label>
                    <label htmlFor="message">
                      Message
                      <textarea row="6" name="message" required />
                    </label>
                    <ReCAPTCHA
                      sitekey={siteKey}
                      ref={refCaptcha}
                    />
                    <input type="button" value="Submit" id="submit-btn" onClick={sendEmail}/>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  )
}
