import { Container, Row, Col, Image } from 'react-bootstrap';
import photo1 from "../assets/img/22f3f00b7f7224cc1b3028f06d235ec52019ChinatownNewYear-2.jpeg";
import photo2 from "../assets/img/66b2a91c327a9f8a66b2a91c327a9f8aLNYFA-43.jpg";
import photo3 from "../assets/img/7aee9aa5233ca6f27aee9aa5233ca6f2LNYFA-4.jpg";
import photo4 from "../assets/img/c62e70db126699c5c62e70db126699c5LNYFA-112.jpg";
import "./about.css";

export const About = () => {
  return (
    <section className="about" id="about">
      <Container>
        <Row>
          <Col>
            <h1>About</h1>
            <h2>History of Lunar New Year for All</h2>
            <p>
              Lunar New Year For All (LNYFA) was founded in 2009. We were inspired by the efforts of St. Pat’s For All and SALGA NYC, who created LGBTQ+ contingents respectively in the St Patrick’s Day Parade and India Day Parade. 
            </p>
            <p>
              Thanks to an Asian Women’s Giving Circle (AWGC) grant applied by Irene Tung, Q-Wave spearheaded the initiative to create a LGBTQ+ contingent within Manhattan Chinatown’s Lunar New Year Parade. Q-Wave called upon community partners, such as GAPIMNY and Project Reach to form this historic contingent. 
            </p>
            <p>
              Initially, the organizers of Better Chinatown were not responsive to our multiple requests to participate, but after 4 months of pressure from English and Chinese media, community groups, politicians and celebrities the contingent was granted entry in 2010!
            </p>
            <p>
              We have marched in Manhattan Chinatown’s parade ever since (minus 2021 due to COVID, when we held a virtual LNYFA over Zoom). We are incredibly grateful to our founders, early supporters and allies, and the many community organizations who join us to march every spring! We are also thankful for our warm and loving relationship with Better Chinatown’s organizers over the years.
            </p>
            <p>
              We do not take our existence for granted, and in 2022 we took stock of our full zodiac cycle (12 years!) of building and celebrating Lunar New Year with our contingent. We engaged in many thoughtful discussions with our community partners, Q-Wave, GAPIMNY, and API Rainbow Parents, regarding our history and how to sustain the work involved to bring LNYFA to life for our communities year after year. 
            </p>
            <p>
              In 2023, LNYFA became its own grassroots community organization with its own steering committee - currently co-chaired by Karen Lee (she/her) and Pearl Wong (they/them). Our purpose in spinning off LNYFA into a separate organization is to clearly define organizing roles and responsibilities and to ensure LNYFA is sustainable for our communities.
            </p>
            <p>
              Karen and Pearl have always felt that the best work worth doing is when someone else is empowered to continue, and expand, that work. We aim to make LNYFA a welcoming and safe space for folks who are newer to community organizing, who may not want to take on community organizing roles for a full year, and/or who love Lunar New Year as much as we do!
            </p>
            <h2>Our Mission</h2>
            <p>
              Lunar New Year For All is a grassroots organization that aims to affirm, connect, and celebrate our queer & trans identities alongside our families and communities during Lunar New Year.
              We march at the Lunar New Year Parade in New York City to 
            </p>
            <ul>
              <li>
                challenge homophobia and transphobia, 
              </li>
              <li>
                empower and bring visibility to queer & trans folk,
              </li>
              <li>
                honor all kinds of families in our community, 
              </li>
              <li>
                create an affirming space for and in our families and communities, and 
              </li>
              <li>
                celebrate Lunar New Year together!
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <div className="about-images-container">
            <Image src={photo2} alt="a group of queer Asians holding signs that say: I am an Asian queer and I respect my family" loading="lazy" />
            <Image src={photo3} alt="large group photo of the Lunar New Year For All LGBTQ+ contingent during 2023" loading="lazy" />
            <Image src={photo1} alt="a group of queer Asians holding signs in Chinese that say: love in Chinatown and queer Asian family" loading="lazy" />
            <Image src={photo4} alt="an Asian lesbian holding a sign that says I am an Asian lesbian and I respect my family with folks walking in the background" loading="lazy" />
          </div>
        </Row>
      </Container>
    </section>
  );
}
