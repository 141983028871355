import { Container, Row } from 'react-bootstrap';
import "./events.css";
import { InstagramEmbed } from 'react-social-media-embed';

export const Events = () => {
  const instagramPostUrls = [
    // add post urls from our IG profile here to display the post,
    // for example:
    // "https://www.instagram.com/p/C3Oioo2PuKS/",
  ]

  function renderInstagramPost() {
    if (instagramPostUrls.length === 0) {
      return (
        <Row className='pt-3 px-3 pb-3 no-events-container'>
          <p className="no-events-message">We currently have no upcoming events, please check back with us closer to the Lunar New Year season!</p>
        </Row>
      );
    }
    return instagramPostUrls.map((url) => {
      return (
        <div className="instagram-embed-post-container">
        <div className="instagram-embed-header"></div>
        <InstagramEmbed title="instagram post with event details" className="instagram-embed" url={url} width={328} />
        </div>
      )
    })
  }

  return (
    <section className="events" id="events">
      <Container >
        <div className="events-border">
          <h1>Events</h1>
          <Row className='px-3 pb-3'>
          <div className="instagram-embed-container">
            {renderInstagramPost()}
          </div>
          </Row>
        </div>
      </Container>
    </section>
  )
}
